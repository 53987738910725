import axios from "axios";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseRecentConversationHistory from "../Assets/Images/square-caret-left-regular.svg";
import CommonValues from "../Common/Utils";
import SupportLoader from "../CommonControls/SupportLoader";

interface NCSupportRecentConversations {
  conversationId: string;
  question: string;
  created: Date;
}

export default function NCChatBotConversationHistory({
  setShowConversationHistory,
  chatBotEmbeded,
}: any) {
  const navigate = useNavigate();
  const [recentConversationLoading, setRecentConversationLoading] =
    useState(false);
  const [selectedConversationId, setselectedConversationId] = useState("");
  const [recentConversations, setRecentConversations] = useState<
    NCSupportRecentConversations[]
  >([]);
  const [isPrevious30DaysConversation, setIsPrevious30DaysConversation] =
    useState(false);
  const [isPrevious7DaysConversation, setIsPrevious7DaysConversation] =
    useState(false);

  const toast: any = useRef("");

  useEffect(() => {
    getRecentConversation();
  }, []);

  const getRecentConversation = () => {
    setRecentConversationLoading(true);
    const token = CommonValues.GetToken();

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/${
        window.location.pathname.startsWith("/bot")
          ? "ncsupportbot/nc-support-bot-conversation-history"
          : window.location.pathname.startsWith("/talktoams")
          ? "nctalktoamsagent/nc-talk-to-ams-conversation-history"
          : window.location.pathname.startsWith("/xml")
          ? "ncxmlagent/nc-xml-agent-conversation-history"
          : window.location.pathname.startsWith("/neo4j")
          ? "ncneo4jagent/nc-neo4j-agent-conversation-history"
          : ""
      }`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        let data: NCSupportRecentConversations[] = response.data;
        setIsPrevious30DaysConversation(
          data.some(
            (item: NCSupportRecentConversations) =>
              new Date(item.created) <
                new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000) &&
              new Date(item.created) >
                new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
          )
        );
        setIsPrevious7DaysConversation(
          data.some(
            (item: NCSupportRecentConversations) =>
              new Date(item.created) >
              new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
          )
        );
        setRecentConversations(data);
        setRecentConversationLoading(false);
      })
      .catch((error: any) => {
        setRecentConversationLoading(false);
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting recent conversations.",
            life: 3000,
          });
        }
      });
  };

  const onRecentConversationClick = (conversationId: string) => {
    setselectedConversationId(conversationId);
    if (chatBotEmbeded) {
      if (window.location.pathname.startsWith("/bot")) {
        setShowConversationHistory(false);
        navigate(`/bot/${conversationId}/embeded`);
      } else if (window.location.pathname.startsWith("/talktoams")) {
        setShowConversationHistory(false);
        navigate(`/talktoams/${conversationId}/embeded`);
      } else if (window.location.pathname.startsWith("/xml")) {
        setShowConversationHistory(false);
        navigate(`/xml/${conversationId}/embeded`);
      } else if (window.location.pathname.startsWith("/neo4j")) {
        setShowConversationHistory(false);
        navigate(`/neo4j/${conversationId}/embeded`);
      }
    } else {
      if (window.location.pathname.startsWith("/bot")) {
        navigate(`/bot/${conversationId}`);
      } else if (window.location.pathname.startsWith("/talktoams")) {
        navigate(`/talktoams/${conversationId}`);
      } else if (window.location.pathname.startsWith("/xml")) {
        navigate(`/xml/${conversationId}`);
      } else if (window.location.pathname.startsWith("/neo4j")) {
        navigate(`/neo4j/${conversationId}`);
      }
    }
  };
  const onCloseChatBotRecentConversationClick = () => {
    setShowConversationHistory(false);
  };
  return (
    <div className="nc-chat-bot-conversation-history">
      <div className="row my-3 mx-2">
        <div className="col-lg-10 col-md-10 col-sm-10 col-10">
          {" "}
          <b>Recent Conversations</b>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 col-2">
          <img
            alt="Close Recent Conversation History"
            src={CloseRecentConversationHistory}
            className="show-cursor"
            height={"24px"}
            width={"24px"}
            onClick={onCloseChatBotRecentConversationClick}
          />
        </div>
      </div>
      <div
        className={`${
          chatBotEmbeded
            ? "recent-conversation-history-accordion-embeded"
            : "recent-conversation-history-accordion"
        } px-3`}
      >
        {!recentConversationLoading ? (
          recentConversations.length > 0 ? (
            <Accordion multiple activeIndex={0}>
              {isPrevious7DaysConversation && (
                <AccordionTab header="Previous 7 days">
                  {recentConversations.map(
                    (conversation: NCSupportRecentConversations) => {
                      if (
                        new Date(conversation.created) >
                        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
                      ) {
                        return (
                          <>
                            <div
                              key={conversation.conversationId}
                              className={`${
                                conversation.conversationId ===
                                selectedConversationId
                                  ? "recent-conversation-card-selected"
                                  : "recent-conversation-card"
                              } p-2`}
                              onClick={() =>
                                onRecentConversationClick(
                                  conversation.conversationId
                                )
                              }
                              title={conversation.question}
                            >
                              <div className="mx-2 recent-conversation-question">
                                {conversation.question}
                              </div>
                            </div>
                          </>
                        );
                      }
                    }
                  )}
                </AccordionTab>
              )}

              {isPrevious30DaysConversation && (
                <AccordionTab header="Previous 30 days">
                  {recentConversations.map(
                    (conversation: NCSupportRecentConversations) => {
                      if (
                        new Date(conversation.created) <
                          new Date(
                            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                          ) &&
                        new Date(conversation.created) >
                          new Date(
                            new Date().getTime() - 30 * 24 * 60 * 60 * 1000
                          )
                      ) {
                        return (
                          <>
                            <div
                              key={conversation.conversationId}
                              className="recent-conversation-card p-2"
                              onClick={() =>
                                onRecentConversationClick(
                                  conversation.conversationId
                                )
                              }
                              title={conversation.question}
                            >
                              <div className="mx-2 recent-conversation-question">
                                {conversation.question}
                              </div>
                            </div>
                          </>
                        );
                      }
                    }
                  )}
                </AccordionTab>
              )}
            </Accordion>
          ) : (
            <div className="text-start">No details are available. </div>
          )
        ) : (
          <div className="d-flex justify-content-center">
            <SupportLoader />
          </div>
        )}
      </div>
    </div>
  );
}
